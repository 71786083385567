<div class="breadcrumbs">
    Daily Read | /<a routerLink="/">home</a>/titles
</div>
<div class="main center">
    <h2>Titles</h2>
    <div class="text-block">
        Select a title below for more details or to subscribe.
    </div>
    <ng-container [ngSwitch]="!!titles">
        <app-spinner *ngSwitchCase="false"></app-spinner>
        <ng-contain *ngSwitchCase="true">
            <p *ngFor="let title of titles">
                <a routerLink="/titles/{{title.alias}}" class="breakAfterMobile">{{title.title}}</a> by {{title.author}}
            </p>    
        </ng-contain>
    </ng-container>
</div>