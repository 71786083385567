import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { SubscriptionsComponent } from "./subscriptions/subscriptions.component"
import { SubscriptionsListComponent } from "./subscriptionslist/subscriptionslist.component"
import { HomeComponent } from "./home/home.component"
import { TitleComponent } from "./title/title.component"
import { TitlesComponent } from "./titles/titles.component"
import { AboutComponent } from "./about/about.component"

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "subscriptions", component: SubscriptionsComponent },
  { path: "subscriptions/:email/:accessHash", component: SubscriptionsListComponent },
  { path: "titles", component: TitlesComponent },
  { path: "titles/:titleAlias", component: TitleComponent },
  { path: "**", redirectTo: "/" }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
