import { HttpErrorResponse } from "@angular/common/http"
import { Component } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { ActivatedRoute, ParamMap } from "@angular/router"
import { Utils } from "../utils/utils"
import { SubscriptionsService } from "./subscriptions.service"

@Component({
    selector: "app-subscriptions",
    templateUrl: "./subscriptions.component.html"
})
export class SubscriptionsComponent {

    public nextChapterTrigger: boolean = false

    public listSubscriptionsEmailForm: FormGroup = null
    public formStatusString: string  = null
    public formHasError: boolean = false
    public formSubmitInProgressBackend: boolean = false
    public formSubmitSuccessful: boolean = false

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private subscriptionsService: SubscriptionsService
    ) {
        this.route.queryParams.subscribe(params => {
            if(params.event === "nextChapterTriggered") {
                this.nextChapterTrigger = true
            }
            else {
                this.setupForm()
            }
        })
    }

    private setupForm(): void {
        this.listSubscriptionsEmailForm = this.formBuilder.group({
            email: null,
        })
    }

    public submitForm(): void {
        this.formStatusString = null
        if(!Utils.emailValid(this.listSubscriptionsEmailForm.value.email)) {
            this.formHasError = true
            this.formStatusString = "Please enter a valid email address."
        }
        if(!this.formHasError) {
            this.formSubmitInProgressBackend = true
            this.subscriptionsService.triggerSubscriptionsList(this.listSubscriptionsEmailForm.value.email).subscribe(
                () => {
                     this.formStatusString = "Please check your email for a link to view/manage."
                     this.formSubmitSuccessful = true
                },
                (error: HttpErrorResponse) => {
                    if(error.status === 404) {
                        this.formStatusString = "No subscriptions exist for this email."
                    }
                    else {
                        this.formStatusString = "An error occurred, please try again."
                    }
                    this.formHasError = true
                    this.formSubmitInProgressBackend = false
                }
            )
        }
    }
}
