import { BrowserModule } from "@angular/platform-browser"
import { NgModule } from "@angular/core"
import { HttpClientModule } from "@angular/common/http"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { TitlesComponent } from "./titles/titles.component"
import { SubscriptionsComponent } from "./subscriptions/subscriptions.component"
import { TitleComponent } from "./title/title.component"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { HomeComponent } from "./home/home.component"
import { SpinnerComponent } from "./utils/spinner/spinner.component"
import { Utils } from "./utils/utils";
import { SubscriptionsListComponent } from "./subscriptionslist/subscriptionslist.component"
import { NgHcaptchaModule } from "ng-hcaptcha";
import { AboutComponent } from './about/about.component'

@NgModule({
    declarations: [
        AppComponent,
        TitlesComponent,
        SubscriptionsComponent,
        TitleComponent,
        HomeComponent,
        SpinnerComponent,
        SubscriptionsListComponent,
        AboutComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule ,
        FormsModule,
        NgHcaptchaModule.forRoot({
            siteKey: "799c4c49-a0b2-452e-b40f-4779ccf6aa9c"
        })
    ],
    providers: [Utils],
    bootstrap: [AppComponent]
})
export class AppModule { }
