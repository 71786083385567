<div class="breadcrumbs">
    Daily Read | /<a routerLink="/">home</a>/about
</div>
<div class="main center">
    <h2>About</h2>
    <p>
        Hi there (👋), hope you have enjoyed using Daily Read! 
    </p>
    <hr>
    <p>
        All public domain content taken from <a href="https://www.gutenberg.org/">https://www.gutenberg.org/</a>. 
    </p>
</div>
