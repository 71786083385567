<div class="breadcrumbs">
    Daily Read | /home
</div>
<div class="main center">
    <img src="assets/Book.png" width="120px">
    <h1>Daily Read</h1>
    <div class="text-block">
        Receive a chapter a day from popular public domain books directly in your inbox.
    </div>
    <a class="btn" routerLink="/titles">View Titles</a><br>
</div>
<div class="footer center">
    <div style="font-size: 18px; font-weight: bold;"><a routerLink="/subscriptions">View/Manage Your Subscriptions</a></div>
    <div style="font-size: 18px; font-weight: bold;"><a routerLink="/about">About</a></div>
</div>
