<div class="breadcrumbs">
    Daily Read | /<a routerLink="/">home</a>/subscriptions
</div>
<div class="main center">
    <h2>Subscriptions</h2>
    <div [ngSwitch]="nextChapterTrigger">
        <div *ngSwitchCase="true" class="text-block"> 
            <div class="success">Request Received.</div>
            <div class="small">
                If your subscription has a next chapter, it will be sent to your shortly.
            </div>
        </div>
        <div *ngSwitchCase="false">
            <div class="text-block">
                Enter your email below to view/manage subscriptions.
            </div>
            <div class="form content-box">
                <form *ngIf="!formSubmitSuccessful" [formGroup]="listSubscriptionsEmailForm" (ngSubmit)="submitForm()">
                    <p>
                        Email: <input type="text" formControlName="email">
                    </p>
                    <ng-container [ngSwitch]="formSubmitInProgressBackend">
                        <app-spinner *ngSwitchCase="true"></app-spinner>
                        <ng-container *ngSwitchCase="false">
                            <input type="submit" value="Submit">
                        </ng-container>
                    </ng-container>
                </form> 
                <p *ngIf="!!formStatusString" [ngClass]="formHasError ? 'form-error' : 'form-success'">
                    {{formStatusString}}
                </p>
            </div>
        </div>
    </div>
</div>