<div class="breadcrumbs">
    Daily Read | /<a routerLink="/">home</a><a routerLink="/titles">/titles</a>/{{titleAlias}}
</div>
<div class="main center">
    <ng-container [ngSwitch]="!!title">
        <app-spinner *ngSwitchCase="false"></app-spinner>
        <div *ngSwitchCase="true">
            <h2>{{title.title}}</h2>
            <p class="title-metadata">
                <span *ngIf="title.author" class="breakAfterMobile"> <b>Author:</b> {{title.author}}</span>
                <span *ngIf="title.author && title.numberOfEntries" class="mobileHide"> | </span>
                <span *ngIf="title.numberOfEntries"><b>Number of Chapters:</b> {{title.numberOfEntries}}</span>
            </p>
            <p *ngIf="title.description"><b>Description:</b><br>{{title.description}}</p>
            <div class="form content-box">
                <b>Subscribe to this Title</b>
                <form *ngIf="!formSubmitSuccessful" [formGroup]="newSubscriptionForm" (ngSubmit)="submitForm()">
                    <p>
                        Email: <input type="text" formControlName="email"><br>
                        <i class="small">Emails will arrive from mailer@dailyread.zumbro.me.</i>
                    </p>
                    <p>
                        Send Period: Daily
                    </p>
                    <p>
                        Send Time: 
                        <select formControlName="sendTimeHour">
                            <option *ngFor="let option of sendTimeHourOptions" [value]="option">{{option}}</option>
                        </select>
                        <select formControlName="sendTimeMinute">
                            <option *ngFor="let option of sendTimeMinuteOptions" [value]="option">{{option}}</option>
                        </select>
                        <select formControlName="sendTimeType">
                            <option *ngFor="let option of sendTimeTypeOptions" [value]="option">{{option}}</option>
                        </select>
                        <br>
                        <i class="small">Time is in your browser timezone.</i>
                    </p>
                    <ng-hcaptcha (verify)="hCaptchaVerify($event)"></ng-hcaptcha>
                    <ng-container [ngSwitch]="formSubmitInProgressBackend">
                        <app-spinner *ngSwitchCase="true"></app-spinner>
                        <ng-container *ngSwitchCase="false">
                            <input type="submit" value="Subscribe">
                        </ng-container>
                    </ng-container>
                </form> 
                <p *ngIf="!!formStatusString" [ngClass]="formHasError ? 'form-error' : 'form-success'">
                    {{formStatusString}}
                </p>
            </div>
        </div>
    </ng-container>
</div>
