import { HttpErrorResponse } from "@angular/common/http"
import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscriber } from "rxjs"
import { Subscription } from "../app.types"
import { SubscriptionsService } from "../subscriptions/subscriptions.service"
import { Utils } from "../utils/utils"
import { SubscriptionsListService } from "./subscriptionslist.service"

@Component({
  selector: "app-subscriptionslist",
  templateUrl: "./subscriptionslist.component.html"
})
export class SubscriptionsListComponent {
    public email: string = null
    public subscriptions: Subscription[] = null
    public statusString: string = null
    public updateStatus: string = null

    constructor(
        private route: ActivatedRoute, 
        private router: Router,
        private subscriptionsService: SubscriptionsService,
        private subscriptionsListService: SubscriptionsListService
    ) {
        this.route.params.subscribe(params => {
            if(Utils.emailValid(params.email) && params.accessHash) {
                this.email = params.email
                this.subscriptionsListService.getSubscriptionsList(params.email, params.accessHash).subscribe(
                    (subscriptions: Subscription[]) => {
                        this.subscriptions = subscriptions
                    },
                    (error: HttpErrorResponse) => {
                        if(error.status === 403) {
                            this.router.navigateByUrl("/subscriptions")
                        }
                        else {
                            this.statusString = "An error occurred, please try again."
                        }
                    }
                )
            }
            else {
                this.router.navigateByUrl("/subscriptions")
            }
        })
    }

    public toggleSubscription(email: string, titleAlias: string, type: string): void {
        const newStatus: string = (type === "stop" ? "Stopped" : "In Progress")
        this.subscriptionsService.updateSubscriptionsListStatus(email, titleAlias, newStatus).subscribe(
            () => {
                const subscription: Subscription = this.subscriptions.find((subscription: Subscription) => subscription.titleAlias === titleAlias)
                subscription.status = newStatus
                this.updateStatus = "Subscription update successful."
            },
            () => this.updateStatus = "Subscription update failed, please try again."
        )
    }
}
