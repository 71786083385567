import { HttpErrorResponse } from "@angular/common/http"
import { Component } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { ActivatedRoute } from "@angular/router"
import { SubscriptionsService } from "src/app/subscriptions/subscriptions.service"
import { TitleService } from "src/app/title/title.service"
import { Subscription, Title } from "../app.types"
import { Utils } from "../utils/utils"

@Component({
    selector: "app-title",
    templateUrl: "./title.component.html"
})
export class TitleComponent {

    public title: Title = null
    public titleAlias: string = null
    public newSubscriptionForm: FormGroup = null

    public formStatusString: string  = null
    public formHasError: boolean  = false
    public formSubmitInProgressBackend: boolean = false
    public formSubmitSuccessful: boolean = false
    private formHCaptchaToken: string = null

    public sendTimeHourOptions: string[] = [
        "12",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11"
    ]
    public sendTimeMinuteOptions: string[] = [
        "00",
        "30"
    ]
    public sendTimeTypeOptions: string[] = [
        "am",
        "pm"
    ]

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private subscriptionService: SubscriptionsService,
        private titleService: TitleService
    ){
        this.route.params.subscribe(params => {
            this.titleAlias = params.titleAlias
            this.titleService.getTitle(params.titleAlias).subscribe((title: Title) => {
                this.title = title
            })
            this.setupForm()
        })
    }

    public hCaptchaVerify(token: string): void {
        this.formHCaptchaToken = token
    }

    public submitForm(): void {
        this.formHasError = false
        const paredSendTime = this.getParsedSendTime()
        const newSubscription: Subscription = {
            email: this.newSubscriptionForm.value.email,
            numberOfEntries: this.title.numberOfEntries,
            sendPeriod: "Daily",
            sendTime: paredSendTime,
            title: this.title.title,
            titleAlias: this.titleAlias
        }
        this.formStatusString = null
        if(!newSubscription.email || !newSubscription.sendPeriod || !newSubscription.sendTime) {
            this.formHasError = true
            this.formStatusString = "Please complete all fields."
        }
        else if(!this.formHCaptchaToken) {
            this.formHasError = true
            this.formStatusString = "Please complete the captcha."
        }
        else if(!Utils.emailValid(newSubscription.email)) {
            this.formHasError = true
            this.formStatusString = "Please enter a valid email address."
        }
        if(!this.formHasError) {
            this.formSubmitInProgressBackend = true
            this.subscriptionService.createSubscription(newSubscription, this.formHCaptchaToken).subscribe(
                () => {
                    this.formStatusString = "Subscription saved!"
                    this.formSubmitSuccessful = true
                },
                (error: HttpErrorResponse) => {
                    if(error.status === 409) {
                        this.formStatusString = "A subscription for this title already exists for the provided email."
                    }
                    else if(error.status === 400) {
                        this.formStatusString = "The email provided has reached the max of three active subscription."
                    }
                    else {
                        this.formStatusString = "An error occurred, please try again."
                    }
                    this.formHasError = true
                    this.formSubmitInProgressBackend = false
                }
            )
        }
    }

    private setupForm(): void {
        this.newSubscriptionForm = this.formBuilder.group({
            email: null,
            sendPeriod: "Daily",
            sendTimeHour: "8",
            sendTimeMinute: "00",
            sendTimeType: "am"
        })
    }

    private getParsedSendTime(): string {
        const chosenHourUserTime = parseInt(this.newSubscriptionForm.value.sendTimeHour, 10)
        const chosenHourUserTimeParsed =
            chosenHourUserTime === 12 && this.newSubscriptionForm.value.sendTimeType === "am" ? 0 :
                chosenHourUserTime === 12 && this.newSubscriptionForm.value.sendTimeType === "pm" ? chosenHourUserTime :
                    this.newSubscriptionForm.value.sendTimeType === "pm" ? chosenHourUserTime + 12 : chosenHourUserTime
        const dateWithChosenTime = new Date(
            (new Date()).setHours(
                chosenHourUserTimeParsed,
                parseInt(this.newSubscriptionForm.value.sendTimeMinute, 10)
            )
        )
        const chosenHourUTC = dateWithChosenTime.getUTCHours().toString()
        const chosenMinuteUTC = dateWithChosenTime.getUTCMinutes().toString()
        return `${chosenHourUTC.length === 1 ? "0" : ""}${chosenHourUTC}:${chosenMinuteUTC.length === 1 ? "0" : ""}${chosenMinuteUTC}`
    }
}
