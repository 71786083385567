import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Subscription } from "../app.types"

@Injectable({
  providedIn: "root"
})
export class SubscriptionsListService {

  constructor(private http: HttpClient) { }

    public getSubscriptionsList(email: string, accessHash: string): Observable<Subscription[]> {
        return this.http.post<Subscription[]>(
            "https://5n2h0avd80.execute-api.us-east-2.amazonaws.com", 
            {
              operation: "list",
              email,
              accessHash
            }
        )
    }
}
