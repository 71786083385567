import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs"
import { Title } from "../app.types"
@Injectable({
    providedIn: "root"
})
export class TitleService {

    constructor(private http: HttpClient) { }

    public getTitle(titleAlias: string): Observable<Title> {
        return this.http.get<Title>(
            `https://7q3kfiyxz8.execute-api.us-east-2.amazonaws.com/text/metadata/${titleAlias}`
        )
    }
}
