import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { EmptyHTTPResponse, Subscription } from "../app.types"

const SUBSCRIPTIONS_ENDPOINT: string = "https://5n2h0avd80.execute-api.us-east-2.amazonaws.com"

@Injectable({
  providedIn: "root"
})
export class SubscriptionsService {

    constructor(private http: HttpClient) { }

    public createSubscription(newSubscription: Subscription, hCaptchaToken: string): Observable<EmptyHTTPResponse> {
        return this.http.post<EmptyHTTPResponse>(
            SUBSCRIPTIONS_ENDPOINT,
            {
                operation: "create",
                ...newSubscription,
                hCaptchaToken
            }
        )
    }

    public triggerSubscriptionsList(email: string): Observable<EmptyHTTPResponse> {
        return this.http.post<EmptyHTTPResponse>(
            SUBSCRIPTIONS_ENDPOINT,
            {
                operation: "trigger",
                email
            }
        )
    }

    public updateSubscriptionsListStatus(email: string, titleAlias: string, status: string): Observable<EmptyHTTPResponse> {
        return this.http.post<EmptyHTTPResponse>(
            SUBSCRIPTIONS_ENDPOINT,
            {
                operation: "update",
                email,
                titleAlias,
                status
            }
        )
    }
}
