import { Component } from "@angular/core"

@Component({
    selector: "app-spinner",
    template: `
        <div class="loadingSpinner">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
    `,
    styleUrls: ["./spinner.component.css"]
})
export class SpinnerComponent {

    constructor() { }
}
