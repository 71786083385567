import { Component } from "@angular/core"
import { Title } from "../app.types"
import { TitlesService } from "./titles.service"

@Component({
    selector: "app-titles",
    templateUrl: "./titles.component.html"
})
export class TitlesComponent {

    public titles: Title[] = null
    
    constructor(private titlesService: TitlesService) {
        this.titlesService.getTitles().subscribe((titles: Title[]) => {
            this.titles = titles
        })
    }
}
